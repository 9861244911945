import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SiAdidas, SiNike } from "react-icons/si";
import { SiFifa } from "react-icons/si";
import { getPredictions } from "../../API/API";
import { createUserPrediction, getMyPredictions } from "../../API/ServerApi";
import { Dialog } from '@headlessui/react'; // Import Headless UI Dialog
import SuccessModal from "../success-failure-models/SuccessModal";
import FailureModal from "../success-failure-models/FailureModal";
import { useAuth } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import PredictionPopup from "../predictionPopup";

interface Team {
    id: number;
    name: string;
    logo: string;
}

const MyPredictionsMain = () => {

    const { t } = useTranslation();
    const { updateBalance, userBalance } = useAuth()
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [isError, setIsError] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showFailureModal, setShowFailureModal] = useState<boolean>(false);
    const [failureMessage, setFailureMessage] = useState<string>("")
    const [myPredictions, setMyPredictions] = useState<any[]>([])
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const getMyPreds = async () => {
        setIsLoading(true)

        try {
            const data = {

            }
            const response = await getMyPredictions(data)
            if (!response || !response?.success) {
                // alert('something went wrong!')
                setIsError(true)
                return
            }
            console.log('response matches', myPredictions)
            setMyPredictions(response?.predictions)
        } catch (error) {
            setIsError(true)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {

        getMyPreds();
    }, []);

    const onclickTakeMePreds=()=>{
        console.log('setIsPopupOpen(true)')
        setIsPopupOpen(!isPopupOpen)
    }

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-b-4 border-gray-500"></div>
            </div>
        );
    }

    if(isError){
        return (
            <div className="flex justify-center items-center h-screen">
                    <span>{t('somethingWrong')} </span>
            </div>
        );
    }
 
    if (!myPredictions?.length) {
        return (
            <div className="flex flex-col justify-center items-center h-screen space-y-4">
                <span>{t('NoPredictionsYet')}</span>
                <button
                    className="p-3 rounded-lg text-xl bg-[#056A4C] text-white mt-4"
                    onClick={onclickTakeMePreds}
                >
                    {t('predictionPage')}
                </button>
                {isPopupOpen&&<PredictionPopup handleClosePopup={()=>setIsPopupOpen(false)} /> }
            </div>
        );
    }
    

    //MOCK SUCCESS AND FAILURE MODALS XXXXXXXXXXXXXXXXXX 
    //**************************** */


    return (
        <div className="overflow-x-auto  py-5 flex justify-center gap-y-8 items-center flex-col w-full">
            <h1 className="text-4xl font-bold text-[#1f5059c9] mb-4">MY PREDICTION</h1>

            <div className="flex flex-col justify-center items-center gap-y-2 w-full">
                <h1 className="text-xl font-bold text-[#1f5059e2]">OUR SPONSORS</h1>
                <div className="flex w-full justify-evenly items-center">
                    <SiNike className="text-gray-500 text-5xl " />
                    <SiFifa className="text-gray-500 text-5xl" />
                    <SiAdidas className="text-gray-500 text-5xl" />
                </div>
            </div>


            <ul className="list-none text-left w-full">
                {
                    myPredictions?.map((pred, index) => {
                        return (
                            <React.Fragment key={index}>
                                <h1 className="text-4xl mt-24 font-bold text-[#1f5059c9]">
                                    {new Date(pred?.createdAt).toLocaleString("en-US", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    })}
                                </h1>

                                {pred?.matches
                                    .map((game: any, index: number) => (
                                        <React.Fragment key={index}>
                                            {/* Game Number Row */}
                                            <li className="text-xs font-semibold mb-2">
                                                <div className="flex justify-between items-center py-2">
                                                    <div >
                                                    <span className="text-3xl mr-4">Game {index + 1}</span>
                                                    <span>
                                                        {game?.status?.long}
                                                    </span>
                                                    {game?.goals?.home!=null&&game?.goals?.away!=null ?
                                                    <span>
                                                        {`   ${game?.goals?.home}-${game?.goals?.away}`}
                                                    </span>
                                                    :null    
                                                
                                                }
                                                    </div>
                                                  
                                                    <span className="text-[9px] text-gray-500">
                                                        {new Date(game.date).toLocaleString('en-US', {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                            hour12: true,
                                                        })}
                                                    </span>
                                                </div>
                                            </li>

                                            {/* Game Information Row */}
                                            <li className="border-b text-xs bg-[#51A186] bg-opacity-65 border rounded-xl px-16 overflow-hidden border-gray-600 mb-4">
                                                <div className="flex justify-between items-center text-center">
                                                    {/* Home Team with logo */}
                                                    <div className="text-[9px] px-4 py-4 flex flex-col gap-y-3 items-center whitespace-nowrap font-semibold">
                                                        <div className="flex items-center">
                                                            <img
                                                                src={game.home_team.logo}
                                                                alt={`${game.home_team.name} logo`}
                                                                className="w-7 h-7 mr-2"
                                                            />
                                                            <span className="text-base">{game.home_team.name}</span>
                                                        </div>
                                                        <div
                                                            className={`text-sm mt-1 cursor-pointer border border-gray-300 bg-white bg-opacity-50 p-2 rounded-md ${game?.choice === '1' ? 'bg-yellow-400 text-black' : 'text-gray-700'
                                                                }`}
                                                        >
                                                            {game?.choice === '1' && (!game?.predicted_score || game?.predicted_score?.home == null || game?.predicted_score?.away == null) ? (
                                                                // SVG check icon
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-5 w-5 text-green-500"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                                                                        clipRule="evenodd"
                                                                    />
                                                                </svg>
                                                            ) : (
                                                                <>

                                                                    {game?.predicted_score?.home!=undefined?game?.predicted_score?.home : ""}
                                                                </>
                                                            )}
                                                        </div>

                                                    </div>

                                                    {/* VS Column */}


                                                    
                                                    <div className="px-4 py-2">
                                                        <div className="flex flex-col gap-y-3 justify-center">
                                                            <span className="text-base">VS</span>
                                                            {/* <div
                                                                className={`text-sm mt-1 cursor-pointer border border-gray-300 font-semibold bg-white bg-opacity-50 p-2 rounded-md ${game?.choice === 'x' ? 'bg-yellow-400 text-black' : 'text-gray-800'
                                                                    }`}

                                                            >

                                                            </div> */}
                                                                <div
                                                            className={`text-sm mt-1 cursor-pointer border border-gray-300 bg-white bg-opacity-50 p-2 rounded-md ${game?.choice === 'x' ? 'bg-yellow-400 text-black' : 'text-gray-700'
                                                                }`}
                                                        >
                                                            {game?.choice === 'x' && (!game?.predicted_score || game?.predicted_score?.home == null || game?.predicted_score?.away == null) ? (
                                                                // SVG check icon
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-5 w-5 text-green-500"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                                                                        clipRule="evenodd"
                                                                    />
                                                                </svg>

                                                         
                                                            )    : null}
                                                        </div>
                                                        </div>
                                                    </div>


                                                    <div className="text-[9px] px-4 py-2 flex flex-col items-center gap-y-3 whitespace-nowrap font-semibold text-center">
                                                        <div className="flex items-center">
                                                            <img
                                                                src={game.away_team.logo}
                                                                alt={`${game.away_team.name} logo`}
                                                                className="w-7 h-7 mr-2"
                                                            />
                                                            <span className="text-base">{game.away_team.name}</span>
                                                        </div>
                                                        <div
                                                            className={`text-sm mt-1 cursor-pointer border border-gray-300 bg-white bg-opacity-50 p-2 rounded-md ${game?.choice === '2' ? 'bg-yellow-400 text-black' : 'text-gray-700'
                                                                }`}
                                                        >
                                                            {game?.choice === '2' && (!game?.predicted_score || game?.predicted_score?.home == null || game?.predicted_score?.away == null) ? (
                                                                // SVG check icon
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className="h-5 w-5 text-green-500"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M16.707 5.293a1 1 0 00-1.414 0L8 12.586 4.707 9.293a1 1 0 00-1.414 1.414l4 4a1 1 0 001.414 0l8-8a1 1 0 000-1.414z"
                                                                        clipRule="evenodd"
                                                                    />
                                                                </svg>
                                                            ) : (
                                                                <>

                                                                    {game?.predicted_score?.away!=undefined?game?.predicted_score?.away : ""}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </React.Fragment>
                                    ))
                                }
                            </React.Fragment>
                        )
                    })
                }


            </ul>

           
            <SuccessModal isOpen={showSuccessModal} onClose={() => setShowSuccessModal(false)} />
            <FailureModal isOpen={showFailureModal} onClose={() => setShowFailureModal(false)} failureMessage={failureMessage} />
            {isPopupOpen&&<PredictionPopup handleClosePopup={()=>setIsPopupOpen(false)} /> }

        </div>



    );
};

export default MyPredictionsMain;
