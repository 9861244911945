import MyPredictionsMain from '../components/myPredictions/MyPredictionsMainComponent'

function MyPredictionsScreen() {
  
  return (
    <section className=" w-full  justify-center items-center p-4 "  >
     <div className=" justify-center gap-y-6 py-4 sm:px-0 px-36 items-center sm:items-start pt-10 ">  

         <MyPredictionsMain />

            </div>

</section>
  )
}

export default MyPredictionsScreen  