// gossipData.ts
const gossipData = [
    {
      id: 1,
      title: "Baku 2023 Taekwondo Championships",
    },
    {
      id: 2,
      title: "Tokyo 2024 Olympic Games",
    },
    {
      id: 3,
      title: "Paris 2024 FIFA World Cup",
    },
  ];
  
  export default gossipData;
  