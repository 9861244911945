import { ReactNode } from "react";
import Navbar from "./components/Navbar";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="flex min-h-screen w-full overflow-hidden bg-[#DDEFE3] flex-col">
      <Navbar />
      <main className="flex flex-grow pt-[80px] max-w-[1400px] mx-auto px-4">
        {children}
      </main>
    </div>
  );
};

export default Layout;
