import React from "react";
import { AiOutlineDownload } from "react-icons/ai"; // Importing a download icon from react-icons

const GamesRules = () => {
  return (
    <div className="container mx-auto p-6 md:p-10 bg-white shadow-lg rounded-lg mt-24 mb-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl md:text-4xl font-bold text-[#14532D]">
          Games Rules & How to Play
        </h1>
        {/* Download Button */}
        <a
          href="/WelcomeText.pdf"
          target="_blank"
          className="flex items-center space-x-2 text-[#15803D] hover:text-[#14532D] transition-colors"
          download
        >
          <AiOutlineDownload className="text-2xl" />
          <span className="text-sm md:text-base font-medium">Download Rules</span>
        </a>
      </div>

      <p className="text-gray-700 text-sm md:text-base mb-4">
        Hello my friend and a warm welcome to <strong>Saudifootballweekly.com</strong>, the only
        information site on Saudi football you will ever need.
      </p>
      <p className="text-gray-700 text-sm md:text-base mb-4">
        We will inform you about everything that goes on in Saudi football, give you news,
        statistics, insights, outlooks, and evaluations.
      </p>
      <p className="text-gray-700 text-sm md:text-base mb-4">
        But it doesn't stop there! We want to turn you into an expert, a true professional when it
        comes to Saudi football. So we invented a challenge to test your skills:{" "}
        <strong>‘The Prediction Challenge’</strong>.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold text-[#15803D] mt-8 mb-4">
        What is the Prediction Challenge?
      </h2>
      <p className="text-gray-700 text-sm md:text-base mb-4">
        It is completely free of charge as it comes as a bonus with your monthly subscription. The
        challenge is to predict the winner in <strong>7 of the weekly games</strong> of the Saudi Pro
        League and the exact result in the other <strong>2 games</strong>.
      </p>

      <h2 className="text-xl md:text-2xl font-semibold text-[#15803D] mt-8 mb-4">
        How Does It Work?
      </h2>
      <ul className="list-disc list-inside text-gray-700 text-sm md:text-base space-y-2 mb-4">
        <li>You receive <strong>8 Tokens</strong> every month with your subscription.</li>
        <li>You can use <strong>2 Tokens per week</strong> to participate in the Prediction Challenge.</li>
        <li>
          All Tokens will be redistributed to participants who predicted the right results through
          three reward pools.
        </li>
      </ul>

      <h2 className="text-xl md:text-2xl font-semibold text-[#15803D] mt-8 mb-4">
        Reward Pools
      </h2>
      <div className="overflow-x-auto">
        <table className="table-auto w-full text-left border-collapse">
          <thead>
            <tr className="bg-[#D1FAE5] text-gray-800">
              <th className="border px-4 py-2">Pool</th>
              <th className="border px-4 py-2">Requirement</th>
              <th className="border px-4 py-2">Reward</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2">Pool 1</td>
              <td className="border px-4 py-2">7 Games Correct</td>
              <td className="border px-4 py-2">
                Tokens equally shared among participants.
              </td>
            </tr>
            <tr className="bg-[#F0FDF4]">
              <td className="border px-4 py-2">Pool 2</td>
              <td className="border px-4 py-2">7 Games Correct + 1 Exact Score</td>
              <td className="border px-4 py-2">
                Share of Pool 1 + Pool 2.
              </td>
            </tr>
            <tr>
              <td className="border px-4 py-2">Pool 3</td>
              <td className="border px-4 py-2">7 Games Correct + 2 Exact Scores</td>
              <td className="border px-4 py-2">
                Share of Pool 1 + Pool 2 + Pool 3.
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <p className="text-gray-700 text-sm md:text-base mt-6">
        If no one gets the results right, we create a jackpot for the respective pool that will roll
        over to the next week. Have fun while you improve your knowledge of Saudi football!
      </p>
    </div>
  );
};

export default GamesRules;
